import './ProductSize.css';

export const ProductSize = () => {
  return (
    <section id="productSize" className='productSize'>
      <h2>Розмірна сітка та заміри виробу</h2>
      <table className='productSize-table'>
  <thead>
    <tr>
      <th>Розмір</th>
      <th>Обхват бедер, см</th>
      <th>Довжина, см</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>M-L</td>
      <td>90-95</td>
      <td>100-105</td>
    </tr>
    <tr>
    <td>L-XL</td>
      <td>95-100</td>
      <td>100-105</td>
    </tr>
    <tr>
      <td>XL-2XL</td>
      <td>100-105</td>
      <td>100-105</td>
    </tr>
    <tr>
      <td>3XL</td>
      <td>105-110</td>
      <td>100-105</td>
    </tr>
    <tr>
      <td>4XL</td>
      <td>110-115</td>
      <td>100-105</td>
    </tr>
    <tr>
      <td>5XL</td>
      <td>115-130</td>
      <td>100-105</td>
    </tr>
    
  </tbody>
</table>

      <p className='productSizeText'><span className='productSize-bolt'>*</span>   Еластичність тканини спрощує підбір розміру.</p>
      <img className='line' src={require('../../Images/line.png')} alt="line" />
    </section>
  );
};
