import './AboutProduct.css'

export const AboutProduct = () => {
  return (
    <section className='aboutProduct'>
      <ul className='aboutProduct-list'>
        <li className='aboutProduct-item item-color'>
          <img className='about-img' src={require('../../Images/aboutProduct1.jpg')}alt="Тканина" />
          <h4 className='aboutProduct-listTitle'>Тканина</h4>
          <p className='aboutProduct-listText'>Високоякісний та еластичний мустанг-рубчик забезпечує свободу рухів має ефектний вигляд, простий у догляді та приємний на дотик і не кошлатиться.</p>
        </li>
        <li className='aboutProduct-item'>
          <img className='about-img' src={require('../../Images/aboutProduct2.jpg')} alt="Рукава" />
          <h4 className='aboutProduct-listTitle'>Хутро</h4>
          <p className='aboutProduct-listText'>Забезпечує відмінне утримання тепла навіть при значних морозах, приємне до тіла, та не потребує складного догляду.</p>
        </li>
        <li className='aboutProduct-item item-color'>
          <img className='about-img' src={require('../../Images/aboutProduct3.jpg')} alt="Фасон" />
          <h4 className='aboutProduct-listTitle'>Фасон</h4>
          <p className='aboutProduct-listText'>Лосини мають сучасний крізь що підкреслює та підтягує фігуру поєднуючи стиль функціональність і простоту підбору розміру.</p>
        </li>
        <li className='aboutProduct-item '>
          <img className='about-img' src={require('../../Images/aboutProduct4.jpg')}alt=" Універсальність" />
          <h4 className='aboutProduct-listTitle'>Універсальність</h4>
          <p className='aboutProduct-listText'>База гардеробу яка легко поєднується з сукнями туніками светрами чи куртками, створюючи багатошаровий і стильний образ.</p>
        </li>
        <li className='aboutProduct-item item-color'>
          <img className='about-img' src={require('../../Images/aboutProduct5.jpg')} alt="Комірець" />
          <h4 className='aboutProduct-listTitle'>Кольори хутра</h4>
          <p className='aboutProduct-listText'>Чорне або біле хутро на вибір до будь-якої тканини допоможе краще підкреслити затишність та вашу індивідуальність.</p>
        </li>
      
      </ul>
    </section>
  );
};
