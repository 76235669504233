export const productsData = [
 
  {
    id: 1,
    imageSrc: require('../Images/products/product1.jpeg'),
    color: 'Чорні з Білим хутром',
    buttonColorName: 'Чорні з Білим хутром',
    price: 799,
    todayPrice: 599,
    discount: 30,
    quantity:1,
    size: "0"
  },
  {
    id: 2,
    imageSrc: require('../Images/products/product2.jpeg'),
    color: 'Сірі з білим хутром',
    buttonColorName: 'Сірі з білим хутром',
    price: 799,
    todayPrice: 599,
    discount: 30,
    quantity:1,
    size: "0"
  },
  {
    id: 3,
    imageSrc: require('../Images/products/product3.jpeg'),
    color: 'Чорні з чорним хутром',
    buttonColorName: 'Чорні з чорним хутром',
    price: 799,
    todayPrice: 599,
    discount: 30,
    quantity:1,
    size:"0"
  },
  {
    id: 4,
    imageSrc: require('../Images/products/product4.jpg'),
    color: 'Сірі з чорним хутром',
    buttonColorName: 'Сірі з чорним хутром',
    price: 799,
    todayPrice: 599,
    discount: 30,
    quantity:1,
    size:"0"
  },
  
];
